import React from 'react';
import './courts.css';

function Courts() {
  return (
    <div className="text-align courts">
    </div>
  );
}

export default Courts;
