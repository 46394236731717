let albumData = {

  albumDataTM: {
    title: "Thomas Moralez Photos",
    alts: 
      ["Wooden Sombrero", 
      "Bloodwood Pen"],
    captions: 
      ["Wooden Sombrero",
      "Bloodwood Pen"], 
    photos: 
      ['/images/tm/wooden-sombrero.jpg', 
      '/images/tm/bloodwood-pen.jpg']
    },
}

export default albumData;